import React from 'react';
import vilLogoRed from '../../../../../assets/images/vil-red-logo.png';
import './vil-branding.scss';
const SectionHeader = ({ name }) => {
  return (
    <div className="section-header">
      <div className="vil-logo">
        <img src={vilLogoRed} alt="" />
      </div>
      <div className="section-head-detail">
        <p className="customfom"> {name}</p>
        <strong>
          Vi<sup>TM</sup> business
        </strong>{' '}
        <span className="pad_l10 section-note">
          Kindly fill the form in BLOCK letters
        </span>
      </div>
    </div>
  );
};

export default SectionHeader;
