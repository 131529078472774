import React, { Component, Fragment } from 'react'
import './terms.scss'
export default class TermsConditionNew extends Component {
	encode = data => {
		let str = data.reduce(function (a, b) {
			return a + String.fromCharCode(b)
		}, '')
		return btoa(str).replace(/.{76}(?=.)/g, '$&\n')
	}
	render() {
		const {
			firstCaps,
			Signcss,
			secondBold,
			imageData,
			imageType,
			isCrf,
			isFlv,
			CustomComponent,
		} = this.props
		const containerClass = isCrf ? 'new-crf-terms' : 'new-caf-terms'
		return (
			<Fragment>
				<page size='A4' className='new-page'>
					{!!CustomComponent && <CustomComponent />}
					<div className='section2 font8 '>
						<div className=''>
							<div className='over_hid'>
								<div className='fleft width49'>
									<ul className='list'>
										<li>
											1. I/We agree to avail Services in accordance with this
											Terms & Conditions (“T&C”) forming part of a duly filled
											and signed Customer Application Form (“CAF”), Schedules /
											Master Service Agreement (“MSA”) regarding agreed by
											Parties as mutually agreed terms between Parties. In the
											event of any conflict the order of precedence is (i) CAF;
											(ii) Schedules/MSA; (iii) PO/ Orders.
										</li>
										<li>
											2. I/We undertake to comply with all Applicable Laws and
											regulations, including without limitation, the provisions
											of the Indian Telegraph Act 1885 and Rules, 1951 made
											thereunder, Indian Wireless Act 1933, IT Act 2000 and TRAI
											Act 1997 and any amendments or replacements made thereto
											from time to time. Any change in Applicable
											Laws/regulations would be applicable with immediate effect
											unless otherwise specified.
										</li>
										<li>
											3. I/We shall not re-sell the services procured from
											Vodafone Idea Limited (“VIL”) to any third party without
											requisite licence from DoT (Department of
											Telecommunications). I/We shall not assign or transfer
											Services procured herein to any third party or any of
											affiliate entity, without VIL’s prior consent.
										</li>
										<li>
											4. I/We ensure that objectionable, obscene, unauthorized
											or any other content, messages or communications
											infringing copyright, Intellectual property right and
											international & domestic cyber laws, in any form or
											inconsistent with the laws of India, are not carried out
											by us.
										</li>
										<li>
											5. VIL shall be under no obligation to activate the
											Services until satisfactory verification of the CAF and
											documents mandated by Applicable Law. Each new Service
											shall require a new CAF to be submitted by the Customer.
										</li>
										<li>
											6. Services shall commence from Service Commencement Date
											and Customer undertakes to pay for the Services from
											Service Commencement Date.
										</li>
										<li>
											7. I/We have read, understood and agree to VIL’s Privacy
											Policy (as updated from time to time) as available at
											www.myvi.com.
										</li>
										<li>
											8. I/We shall, when required, assist VIL/any Government
											agency to counteract any unlawful activity on the
											Services.
										</li>
										<li>
											9. I/We shall undertake that Services from VIL will not be
											used for any call center or other OSP-related service
											without prior intimation/registration with concerned
											Government authority.
										</li>
										<li>
											{!!isFlv
												? `10. I/We shall not use or permit the Services for: 
                        (a) establishing a connection to the gateway between 
                        Internet & PSTN/ISDN/PLMN in India is not permitted & 
                        I/We must ensure that the voice traffic over the enterprise 
                        data network and the public switched telephone network
                        must be strictly separated via logical partitioning of the 
                        EPABX so that proper management of the EPABX and no mixing of 
                        calls between the two networks can occur for the purpose of 
                        toll bypass; or (b) interconnectivity is not permitted for 
                        ISPs offering Internet Telephony Services and for ISPs usage; or 
                        (c) being used to route unauthorized telecommunication network 
                        including to prohibited destinations; or (d) lease/resell or 
                        create any third party right over the Services provisioned or 
                        (e) negligence use by us or our employees or agents thereby 
                        being responsible for ensuring that the Services are used 
                        legally and in compliance with the existing regulations; or 
                        (f) excess or excessive usage or for any unusual patterns in 
                        the usage and/or for any unwarranted/not permitted usage; or 
                        (g) use, directly or indirectly, VIL’s SMS centres, network or 
                        infrastructure or any part thereof for sending bulk or unsolicited 
                        messages to VIL customers or other mobile phone users in any manner
                        whatsoever; or (h) reselling or creating any third party right of use 
                        over the Service provisioned herein, unless to a Virtual Network 
                        Operator having requisite and valid license to provide the Service 
                        thereto; which has been validated by us; or (i) acquiring any customers 
                        or promote any messages through unethical practices.`
												: `10. I/We agree and undertake not use the services for any
                        of the following activities: (a) Voice communication by
                        means of dialling a telephone number (PSTN/ISDN/ PLMN)
                        until and unless I/We hold appropriate licenses and
                        approvals and the same is informed to VIL. (b)
                        Originating/terminating the voice communication from/at a
                        telephone in India. (c) Establishing connection to any
                        Public switched network in India and/or establishing
                        gateway between Internet & PSTN/ISDN/PLMN in India, until
                        and unless I/We hold appropriate licenses and approvals
                        and the same is informed to VIL. (d) Use of dial up lines
                        with outward dialling facility from nodes. (e) Routing
                        public Internet traffic between links of two or more
                        Internet Service Providers (ISPs).`}
										</li>
										<li>
											11. VIL shall not be liable for any cost to the customer
											in any manner whatsoever and/or for any loss of profits,
											goodwill, consequential losses (including any actions
											brought by a third party), whether direct or indirect.
										</li>
										<li>
											12. VIL makes no warranties or representations, express or
											implied, either in fact or by operation of law, statutory
											or otherwise, including warranties or terms of
											merchantability, satisfactory quality, fitness for
											purpose, title or non-infringement; unless otherwise
											stated in the respective Service Schedules.
										</li>
									</ul>
								</div>
								<div className='fright width49'>
									<ul className='list'>
										<li>
											13. I/We shall take precautions in accordance with
											industry best practice to ensure that no Virus is
											introduced in VIL’s network.
										</li>
										<li>
											14. I/We shall co-operate or provide all assistance,
											access and share correct, accurate and complete details to
											enable VIL to resolve any technical or system related
											issues pertaining to the Services.
										</li>
										<li>
											15. I/We shall indemnify and keep indemnified VIL (or its
											officers, employees, affiliates and their agents) against
											all Claims arising under or in connection with: (i)
											Customer’s or End User’s (a) use of the Services for any
											purpose other than as specified in T&C / non-compliance of
											CAF terms; (b) acts or omissions resulting into loss or
											damage to real and tangible property, caused by (or
											relating to the strict liability); (c) transmission of
											illegal or unlawful content; and (d) infringement of
											privacy and data protection laws; and (ii) fines /
											penalties imposed on VIL by any governmental authorities
											due to Customer’s or End User’s acts or omissions.
										</li>
										<li>
											16.A Party’s aggregate liability under or in connection
											with the Services (whether is contract, tort (including
											negligence), breach of statutory duty, indemnity or
											otherwise) during consecutive 12 month period starting on
											the date of last signature of the Service Schedule
											(“Liability Period”) shall not exceed to a sum equal to
											hundred per cent (100%) of the aggregate value of all
											Charges paid or payable in respect of the Liability
											Period. Where Charges have been paid or payable for less
											than 12 months in a Liability Period, a Party’s liability
											shall not exceed the average monthly charge paid or
											payable for that Liability Period multiplied by 12. This
											liability cap shall not apply to non-payment of Charges or
											Exit Charges or Indemnification Claims or for wilful
											default or wilful abandonment; or (iii) fraud or
											fraudulent misrepresentation; or (iv) any liability which
											cannot be excluded by law.
										</li>
										<li>
											17. Lock in Period for all Services commences upon Service
											Commencement Date as mentioned in the relevant Schedules
											or as agreed in writing or email exchanges.
										</li>
										<li>
											18. T&C stated herein is effective as of from the Service
											Commencement Date and shall continue unless otherwise
											terminated by either Party of all Services activated
											herein in accordance terms stated herein.
										</li>
										<li>
											19. We understand and agree that during implementation of
											Services on any of or site, if there is requirement of
											additional capex or site becoming completely non-feasible
											inspite of VIL's of endeavours to establish Service/link,
											then we shall be agreeable to pay accordingly for such
											additional capex or expenses, as discussed and agreed
											between both Parties.
										</li>
										<li>
											20. For any cross connect, this would be our
											responsibility and to our account.
										</li>
										<li>
											21. Any travel & man hours required support shall be
											charged as per actual, unless otherwise agreed.
										</li>
										<li>
											22. Shifting of Services from location would be subject to
											VIL’s feasibility and thereafter will be charged
											additionally and as per actual.
										</li>
										<li>
											23. Either Party may terminate the Services for
											convenience on thirty (30) calendar day’s written notice
											except that if the termination of the Service by the
											Customer is effective prior to the expiry of any relevant
											Lock in Period, the Customer shall pay the applicable Exit
											Charge and any other sums payable in accordance with the
											T&C or agreed between Parties. Formal written notices to
											be given only to the registered address of the Parties or
											to such other address as communicated by the respective
											Party from time to time. Customer shall also be liable to
											send a copy of the notice on the VIL email address
											provided viz. fltermination.helpdesk@vodafoneidea.com and
											keep a copy of the acknowledgement for their record and
											reference.{' '}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</page>
				<page size='A4'>
					<div className='section2 font8'>
						<div className='over_hid'>
							<div className='fleft width49'>
								<ul className='list'>
									<li>
										24. In the event, VIL chooses to suspend the Services
										instead of termination due to non-payment of Charges due for
										a period of ninety (90) days, the Customer shall be liable
										to pay the charges during the suspension period.
									</li>

									<li>
										25. Consequences of Termination: (a) Termination of this
										Service for any reason does not limit any rights or remedies
										to which a Party may be entitled under this T&C or by law or
										at equity (including VIL’s right to Claim (i) the Exit
										Charges in the event of termination of a Link prior to the
										expiry of the Lock-in Charges, (ii) all sums due prior to
										termination and for Services rendered between the issuance
										of a termination notice until the cessation of the
										applicable Services). (b) Termination shall not affect any
										accrued rights or liabilities of either Party, nor the
										coming into force or the continuance in force of any
										provision of this T&C which is expressly or by implication
										intended to come into or continue in force on or after such
										termination. (c) Upon termination of this Agreement VIL
										shall retain title, and ownership to the Links, and the
										associated Materials and all Control Data. (d) All materials
										of either Party in the control or possession of the other
										Party that contain or bear the other Party’s Intellectual
										Property Rights or Confidential Information shall at the
										request of that Party be destroyed or returned to that
										Party. (e) Termination of this Agreement or any Link
										hereunder the Customer shall promptly return any VIL
										property including materials, equipment to VIL; in case of
										failure by Customer to return then VIL shall have right to
										charge back the Customer in their final invoice. (f)
										termination of any Service/Link will not affect any other
										Service/Link Services.
									</li>
									<li>
										26. I/We acknowledges (i) VIL as an intermediary has no
										editorial right over any information transmitted on use of
										the Links and Services by the Customer; and (ii) VIL may
										have to collect, store and process personal data and usage
										data while providing the Services and consent to such
										collection, storage and processing.
									</li>
									<li>
										27. Parties shall not use other Party’s marks or register
										any confusingly similar devices, logos, trade names,
										trademark or domain names and shall not do any act
										detrimental to the other Party’s marks or goodwill or
										reputation.
									</li>
									<li>
										28. Each Party shall comply with all Applicable Law (as
										applicable to it) and the Customer shall not do or omit to
										do any act or thing which may prejudice the interests of VIL
										or cause VIL to be in breach of its telecom license
										conditions. Each Party shall also comply with all Applicable
										Laws relating to bribery and corruption and (i) shall not
										do, or omit to do, any act that will cause the other Party
										to be in breach of any such Applicable Law, (ii) shall not
										give or receive any bribes, including in relation to any
										public official; and (iii) shall maintain an effective
										anti-bribery compliance regime that monitors compliance and
										detects violations.
									</li>
									<li>
										29. Parties do hereby consent that all Confidential
										Information or documentation disclosed between them relating
										to business including but not limited of technical nature or
										involves research and development, that is rightfully
										obtained, will be kept under strict confidentiality and the
										recipient Party shall not be permitted to reproduce any
										copies or ownership of the same. The obligation of
										confidentiality shall be valid till the tenure of T&C.
									</li>
									<li>
										30. A Party in breach of this CAF T&C shall indemnify & keep
										indemnified the other Party against all or any losses,
										damages and claims arising under or in connection with (i)
										any breach of the provisions on compliance with Applicable
										Law; or (ii) any breach of the provisions on compliance with
										Anti-bribery & Anti-corruption obligations; or (iii) any
										breach of its confidentiality obligations; or (iv)
										infringement of intellectual property rights.
									</li>
									<li>
										31. Either Party may terminate this CAF T&C immediately on
										formal written notice and without liability, if the other
										Party: (i) becomes subject to an Insolvency Event; or (ii)
										becomes subject to a Force Majeure Event which continues for
										forty-five (45) consecutive days; or (iii) commits a
										material breach, and, in the case of a breach capable of
										remedy, fails to remedy such breach within thirty (30)
										calendar days after receiving formal written notice to do
										so; or (ii) commits a series of breaches which when taken
										together amount to a material breach.
									</li>
								</ul>
							</div>
							<div className='fright width49'>
								<ul className='list'>
									<li>
										32. VIL may terminate this T&C immediately on formal notice
										to the Customer and without liability, if there is a change
										in control of the Customer which results in the Customer
										being controlled by: (i) a competitor; or (ii) a person in
										relation to which VIL has a documented internal policy of
										not trading; or (iii) Customer has violated the Applicable
										Law.
									</li>
									<li>
										33. VIL may suspend or terminate Services, take other action
										and/or collect any applicable financial penalty as levied by
										any governmental authority for any unauthorized or unlawful
										use of the Services. In the event Customer fails to pay any
										Charges due within ninety (90) calendar days of the payment
										due date, VIL shall have the right to terminate the T&C as
										applicable by serving Customer with seven (7) calendar days
										written notice of such termination. If such termination is
										within the Lock in Period, VIL shall be entitled to recover
										not only the Charges but also Exit Penalty.
									</li>
									<li>
										34. Damages alone may not be an adequate remedy for breach
										by a Party and accordingly a Party shall be entitled to seek
										remedies of injunction, specific performance or other
										equitable relief for any threatened or actual.
									</li>
									<li>
										35. This T&C and related documents shall be subject to laws
										of India, with jurisdiction of Courts in Mumbai, over any
										dispute, controversy or claim (including any dispute
										regarding its existence, validity or termination) herein.
									</li>
									<li>
										36. Neither Party without prior written consent of other,
										not assign, novate, subcontract or otherwise transfer any of
										its rights or obligations, save that VIL may assign, novate,
										subcontract or otherwise transfer any of its rights or
										obligations herein and also subcontract after due intimation
										to us/Customer.
									</li>
									<li>
										37. This arrangement between Parties herein are on principal
										to principal basis.
									</li>
									<li>
										38. Neither Party shall be responsible for failure due to a
										‘Force Majeure Event’, provided that the subjected Party:
										(i) has promptly notified of the circumstances which may
										result in failure to perform its obligations; (ii) could not
										have avoided the effect of the Force Majeure Event by taking
										precautions.
									</li>
									<li>
										39. VIL shall not be liable to the Customer or End User (i)
										for damages to or loss of data, information or other content
										in the course of implementation or usage of the Services;
										(ii) for any malfunctions or interruptions in the Services
										caused as a result of the use of the Services by third
										parties or any Force Majeure Event; (iii) for any message
										content, or regarding any interactive actions or omissions
										by or on behalf of the Customer or its End User; and (iv)
										for use of the Services and the transacted
										business/transmitted communication. VIL does not guarantee
										of complete security of Customer’s communications.
									</li>
									<li>
										40. Customer agrees that Services cannot be downgraded
										during the Lock in Period unless Parties agree in writing.
										In case the downgrade is done prior to the expiry of the
										applicable Lock in Period, the same can be done by providing
										prior written notice of not less than thirty (30) days and
										the Customer paying the Early Downgrade Charge related to
										the applicable Services. ‘Early Downgrade Charge’ means an
										amount equivalent to the difference between agreed monthly
										charges for existing and downgrades services, multiplied by
										the number of months remaining in the original Lock in
										Period (measured from the date of downgrade to the expiry of
										the Lock in Period). Early Downgrade Charges needs to be
										paid upfront by Customer before implementation of the
										downgrade. A reduction in core Service offering leads to a
										reduced commercial implication is considered as a downgrade.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</page>
				<page size='A4'>
					<div className='section2 font8'>
						<div className='over_hid'>
							<div className='fleft width49'>
								<ul className='list'>
									<li>
										41. The date on which We/Customer has certified in writing
										that the acceptance criteria has been met shall be deemed
										the Service Commencement Date. Any acceptance test that has
										not been rejected in accordance with the foregoing clauses
										within five (5) Business Days following receipt of an
										Acceptance Test Results Report shall be deemed to be
										accepted, and the following Business Day shall be considered
										as the Service Commencement Date.
									</li>
									<li>
										42.VIL shall implement Service with effect from the Service
										Commencement Date. Customer undertakes to pay (from the
										Service Commencement Date) for the Services identified in
										relevant CAF & each applicable relevant documents and shall
										comply with its obligations set out in this T&C stated
										herein.
									</li>
									<li>
										43. Period/Term: The rates charged for the Service under
										each Order shall be valid and fixed for a period as agreed
										in the respective order attached to the relevant CAF, which
										shall at all times be not less than the Lock in Period
										agreed herein
									</li>
									<li>
										44. All prices for Services are exclusive of applicable
										Taxes, fees, levy’s, duties which will be payable extra at
										applicable prevalent rates, as agreed between Parties.
										Telecom Regulatory Authority of India (TRAI) mentioned
										change in tariff, if applicable, on Service would be
										implemented immediately. Any change in Taxes, government
										levies, license fees and duties to be borne by Customer.
									</li>
									<li>
										45. Any installation, commissioning or maintenance of any
										equipment or hardware used for the Service provisioned shall
										be our responsibility unless the same is supplied by VIL or
										mutually agreed. We shall be solely be responsible / liable
										for any damages/mishandling/theft of such equipment or
										hardware deployed by VIL towards provisioning of Services.
										Additional cost would be charged for replacement of
										equipment or hardware.
									</li>
									<li>
										46. Service payment terms shall be quarterly in advance
										unless otherwise agreed by Parties for recurring charges /
										one time charges or for any other charges thereto.
									</li>
									<li>
										47. I/We as Customer agree that We must raise dispute on the
										received invoices from VIL within thirty (30) days,
										otherwise We waive our right of dispute the invoice. Payment
										of invoices to be done as per due date mentioned on the
										respective invoice and for delay's in payments of Service
										charges invoices VIL shall have a right to charge us
										interest @2% per month.
									</li>
									<li>
										48. I/We hereby acknowledge that in case you have not been
										provided regular GST Registration number from mentioned
										states/UT to VIL, I/We will become disqualified for claiming
										input Tax Credit of GST (CGST, SGST/UTGST, IGST) charged by
										VIL on provision of services being made in the mentioned
										States/UT to I/We. Further I/We also hereby declare that
										while making invoice payments you will neither hold nor make
										any deductions of any kind on account of non-availment of
										Input Tax Credit by us in the said States/UT.
									</li>
									<li>
										49. Service level agreement: VIL provides Service Levels as
										per the media (Fiber protected: 99.5%, Radio and Linear
										Fiber: 98.5%). Customers shall allow VIL or its
										representatives to carryout maintenance activities as and
										when required by VIL.
									</li>
									<li>
										50. For additional new Services or upgrade of Links already
										provisioned these would be treated as a separate Order and
										pricing would be accordingly reviewed.
									</li>
									<li>
										51. We understand that in the event the term or usage of a
										Service/Link extends beyond the agreed expiry date or
										termination date of this CAF, then the T&C of this CAF shall
										be extended to the expiration/termination of the applicable
										Service/Link. We shall be bound to make all payments of
										Service/Link during the extended period as per last agreed
										pricing terms.
									</li>
									<li>
										52. It is further agreed that the T&C under this CAF binds
										us, their executors, administrators, successors and
										permitted assignees to the T&C of the CAF, along with the
										terms of MSA & Schedule (if any).
									</li>
									<li>
										53. If any term or provision of this T&C is held to be
										invalid, illegal or unenforceable in any respect, such
										provision will be ineffective only to that extent without
										invalidating the remainder of such provision or this T&C.
									</li>
								</ul>
							</div>
							<div className='fright width49'>
								<ul className='list'>
									<li>
										54. Except as required by Applicable Law, the Customer shall
										not issue any announcement or other communication to any
										third party concerning its dealings with VIL in relation to
										this T&C in any promotional, advertising or other materials,
										without VIL’s prior express written consent. Customer shall
										not issue any announcement or other communication to any
										third party concerning its dealings with VIL in relation to
										this T&C.
									</li>
									<li>
										55. VIL may change, amend or revised the above T&C at any
										time as and when necessary in order to comply with any
										statutory, legal or regulatory requirements and the Customer
										agrees to abide by such modified terms and conditions. Such
										changes, amendments or revisions shall be deemed effective
										upon posting an update and duly dated regulatory compliance
										to the Customer via email, post or through any other medium
										opted by VIL.
									</li>

									<li style={{ display: 'block' }}>
										56. <b>DEFINITIONS </b>
										<br />
										<b>“Applicable Law”</b> means all laws, legislation,
										regulations, directions, binding codes of practice, or rules
										or requirements of any relevant government or governmental
										agency including TRAI or DoT as may be applicable to the
										Services;
										<br />
										<b> “Charge”</b> shall include all payments under the T&C
										which are due and payable by the Customer to VIL, whether as
										Annual Recurring Charges or Non-Recurring Charges and
										includes one-time registration, testing and installation,
										Service Equipment and Service rental, billing, network
										service/feature change, transfer /shifting, reconnection,
										usage based charges and Security and all other fees in
										respect of provision of the Service and chargeable to the
										Customer; <br />
										<b>“Confidential Information”</b> means all financial,
										business and technical or other data and all other
										confidential information (whether written, oral or in
										electronic form or on magnetic or other media) concerning
										the business of a disclosing Party that a receiving Party
										receives or accesses as a result of any discussions or
										dealings under this T&C or learns during visits to a
										disclosing Party’s premises.
										<br />
										<b>“Force Majeure Event”</b> means an event which is beyond
										a Party's control, including: (i) abnormal weather
										conditions; (ii) nuclear, chemical or biological
										contamination; (iii) war, civil commotion or terrorist
										attack; (iv) interruption or failure of a utility service
										including electric power, gas or water; (v) strikes or other
										forms of industrial action by the employees, agents or
										subcontractors of that Party; (vi) acts of God, floods or
										earthquakes; (vii) the imposition of a sanction, embargo or
										breaking off of diplomatic relations; or (viii) expiry,
										suspension or termination of the telecom license of VIL.
										<br />
										<b>“Exit Charge”</b> means an amount equivalent to the
										agreed monthly charges multiplied by the number of months
										remaining in the Lock in Period (measured from the date of
										termination to the expiry of the Lock in Period);
										<br />
										<b>“Lock in Period”</b> means the period commencing from the
										Service Commencement Date, during which a Customer cannot
										terminate the T&C / Services, whether in whole or part,
										without the payment of Exit Charges as specified in the
										relevant Schedules;
										<br />
										<b> “Services”</b> as specified in the respective CAF and
										Service Schedule;
										<br />
										<b>“Service Commencement Date”</b> means the date when the
										Services are certified by VIL as being active or the date
										when the Services are delivered to the Customer as confirmed
										by VIL in writing. <br />
										<b>“Tax”</b> or <b>“Taxes”</b> means all Goods and Services
										Tax (“GST”), CGST, SGST, UTGST, IGST, GST compensation cess
										or all indirect taxes, including VAT, sales tax, service
										tax, excise duty, privilege tax, duties, fees, levies or
										surcharges imposed by, or pursuant to the laws, statutes or
										regulations of any governmental agency or authority;
									</li>
									<div className='terms-sign '>
										{firstCaps !== '' ? (
											<div style={{ padding: '10px' }} className={Signcss}>
												{firstCaps + '  .  '}
												<span className='under_line'>{secondBold}</span>
											</div>
										) : (
											''
										)}
										{imageData != null && imageData !== '' ? (
											<div style={{ padding: '10px' }} className='signate_img'>
												<img
													src={
														imageData != null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  this.encode(imageData)
															: ''
													}
													alt={'Company Seal'}
												/>
											</div>
										) : (
											''
										)}
									</div>
								</ul>
							</div>
						</div>
					</div>
				</page>
			</Fragment>
		)
	}
}
